import { useLocation } from '@reach/router'
import { graphql } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import Breadcrumb from '../components/Core/Breadcrumb'
import { head } from '../components/head'
import Layout from '../components/layout'
import CmsHeader from '../components/Shared/CmsHeader'
import Sitemap from '../components/Sitemap'
import * as styles from './styles/cms.module.scss'
import Modal from '../components/Core/Modal'
import ModalGeneral from '../components/Shared/ModalGeneralContent'
import replaceHifeAndAddLocaleEn from '../utils/replaceHifeAndAddLocaleEn'
import useCustomLocale from '../hooks/useCustomLocale'

export const Head = head('umbracoCmsPage')

export default function cms(props: any) {
  const { data } = props
  const page = data.umbracoCmsPage
  const residences = data.allUmbracoResidencePage.nodes
//  const {
//     allPageIntl: { edges: pagesIntl },
//     allUmbracoResidencePage: { nodes: residences },
//   } = data
  const popinGeneralVisible = page.popinGeneralVisible
  const [pageIsSitemap, setPageIsSitemap] = useState(false)
  const location = useLocation()
  const contentRef = useRef<any>(null)
  const currLocale = useCustomLocale()
  const [showGeneral, setShowGeneral] = useState<boolean>(false)

  // console.log("page ", page)
  console.log("popinGeneralVisible ", popinGeneralVisible)

  useEffect(() => {
    window.scrollTo(0, 0)

    const cookiesDeclarationTag = contentRef.current?.querySelector(
      '#cookies-declaration-tag'
    )
    if (cookiesDeclarationTag) {
      console.log('cookiesDeclarationTag', cookiesDeclarationTag.parentNode)
      if (cookiesDeclarationTag.parentNode) {
        const cookiesScript = document.createElement('script')
        cookiesScript.id = 'CookieDeclaration'
        cookiesScript.src =
          'https://consent.cookiebot.com/1f557895-b4ca-44cf-97f2-edf24df6902d/cd.js'
        cookiesScript.type = 'text/javascript'
        cookiesScript.async = true
        cookiesDeclarationTag.parentNode.insertBefore(
          cookiesScript,
          cookiesDeclarationTag.nextSibling
        )
      }
    }

    if (page && page.pageIdentifier === 'plan-du-site') {
      setPageIsSitemap(true)
    }

    if (typeof window !== 'undefined' && window.document) {
       if (
        popinGeneralVisible &&
        !sessionStorage.getItem('single_cms_' + page.id + '_already_shown')
      ) {
        setTimeout(() => {
          setShowGeneral(true)
        }, 2000)
      }
    }

  }, [])

  const hideModal = () => {
    setShowGeneral(false)
    sessionStorage.setItem('single_cms_' + page.id + '_already_shown', 'true')
  }

  return (
    <Layout>
      <CmsHeader
        title={page?.title}
        description={page?.subtitle}
        bg={page?.backgroundColor}
      />
      <div
        ref={contentRef}
        className={styles.cmsContent}
        dangerouslySetInnerHTML={{ __html: replaceHifeAndAddLocaleEn(page?.paragraph, currLocale)}}
      ></div>
      {pageIsSitemap && <Sitemap pagesIntlData={data.allPageIntl
      .edges} residencesData={residences} />}

      <Breadcrumb
        data={[
          {
            title: page?.title,
            link: location.pathname,
            id: '' + location.pathname,
          },
        ]}
      />
      <div id="modal-single-cms">
        { popinGeneralVisible && page.popinToDisplay && (
          <Modal
            data={page.popinToDisplay}
            visible={showGeneral}
            onClose={hideModal}
            width={'1018px'}
            secondaryBg="FFFFFF"
            size="lg"
            general
            darkClose
          >
            <ModalGeneral data={page.popinToDisplay} />
          </Modal>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($name: String, $lang: String) {
    umbracoCmsPage(name: { eq: $name }, lang: { eq: $lang }) {
      id
      name
      pageIdentifier
      title
      subtitle
      paragraph
      backgroundColor
      metaTitle
      metaDescription
      metaFollow
      metaIndex
      isPrivacyPage
      popinGeneralVisible
      popinToDisplay {
        _id
        name
        popinType
        subtitle
        stayTitle
        staySubtitle
        isActive
        popinGTMEventName
        cta {
          name
          target
          type
          url
        }
        _urls {
          en_us
          fr
        }
        colorCombination
        contactPhoneNumber
        contentTypeAlias
        title
        _url
      }
      twitterCard {
        id
        url
      }
      facebookCard {
        id
        url
      }
      _url
      _urls {
        fr
        en_us
      }
    }
    allPageIntl(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          name
          slug
          pageIdentifier
          lang
          uri
          pageIntlId
          pageIntl
          alternates {
            isDefault
            name
            uri
            lang
          }
        }
      }
    }
    allUmbracoResidencePage(filter: { lang: { eq: $lang } }) {
      nodes {
        id
        name
        _url
        _urls {
          fr
          en_us
        }
        shortName
        shortDescription
        title
        comingSoon
      }
    }
  }
`
