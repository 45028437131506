import React from 'react'
import Text from '../../Core/Text'
import * as styles from './cmsHeader.module.scss'

interface CmsBg {
  bg?: string
  title: string
  description?: string
}

export default function CmsHeader(props: CmsBg) {
  const { bg = '21534F', title, description } = props
  return (
    <>
      <div
        className={styles.cmsBg}
        style={{ '--bg': `#${bg}` } as React.CSSProperties}
      ></div>
      <div className={`${styles.cmsHeader} container`}>
        <Text
          tag="h1"
          as="h2"
          className="font-heavy uppercase"
          style={{ color: `#${bg}` }}
        >
          {title}
        </Text>
        {description && (
          <Text
            tag="div"
            as="subtitleH1"
            className="p"
            dangerouslySetInnerHTML={{ __html: description }}
          ></Text>
        )}
      </div>
    </>
  )
}
